// Импортируем модуль "centrifuge" чтобы определить window.getCentrifugeConnection для старого кода
import './centrifuge';
import { chunkLoader } from './spa/utils/chunkLoader';

const initReactContainers = async () => {
    // на самом деле, тут chunkLoader не нужен, т.к. страницы не в спа, но на всякий случай
    chunkLoader(() => import(/* webpackChunkName: "base-entry-comments" */ './comments'))
        .then(({ default: commentsInitializer }) => {
            commentsInitializer();
        });

    chunkLoader(() => import(/* webpackChunkName: "base-entry-chunks" */ './chunks'))
        .then(({ default: chunksInitializer }) => {
            chunksInitializer();
        });

    chunkLoader(() => import(/* webpackChunkName: "base-entry-likes" */ './likes'))
        .then(({ default: likesInitializer }) => {
            likesInitializer();
        });

    chunkLoader(() => import(/* webpackChunkName: "base-entry-main-menu-block" */ './main_page/components/MainMenu/initializer'))
        .then(({ mainMenuInitializer }) => {
            // for base-double and similar templates
            mainMenuInitializer();
        });

    const isRedactorPage = document.getElementsByClassName('blog-post-redactor').length > 0;
    if (document.querySelectorAll('[data-setup]').length && !isRedactorPage) {
        import(/* webpackChunkName: "video-js" */ './videojs.js');
    }
};

document.addEventListener('DOMContentLoaded', initReactContainers);
